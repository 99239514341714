$website-nav-width: 280px;
$website-content-width: 1080px;
$website-content-padding: 4rem;
$website-content-padding-mobile: 2rem;

// we want to optimize the usage of space, and therefore our
// desired breakpoint falls between two of the standard
// Polaris breakpoints
$website-breakpoint-mobile: 1100px;
$website-breakpoint-xxs: 576px;
$website-breakpoint-xs: 768px;

$squid-ink: #232f3e;

@mixin extra-small-screen() {
  @media (max-width: $website-breakpoint-xs) {
    @content;
  }
}
@mixin extra-small-screen-up() {
  @media (min-width: $website-breakpoint-xxs + 1) {
    @content;
  }
}
@mixin small-screen() {
  @media (max-width: $website-breakpoint-mobile) {
    @content;
  }
}
@mixin large-screen() {
  @media (min-width: $website-breakpoint-mobile + 1) {
    @content;
  }
}
