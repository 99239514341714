@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;
@use './typograhy.scss' as typography;
@import './constants.scss';

.website-table-items-counter {
  color: awsui.$color-text-form-secondary;
  font-weight: normal;
}

.website-horizontal-divider {
  border: none;
  border-top: 1px solid awsui.$color-border-divider-default;
  margin: 1.5rem 0;
}

.max-width-container {
  padding: 0 $website-content-padding-mobile;
  @include large-screen {
    max-width: $website-content-width;
    padding: 0 $website-content-padding;
    box-sizing: content-box;
  }
  margin: 0 auto;
}

.full-width-container {
  padding: 0 $website-content-padding-mobile;
  @include large-screen {
    padding: 0 $website-content-padding;
  }
}

// Ensure that when scrolling an anchor into view, the heading tag content
// appears below the fixed position topnav.
// NB this height can vary depending on mobile/desktop and wrapping,
// but we only need a rough value here.
html {
  scroll-padding-top: 9rem;
}
